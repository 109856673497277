import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import shereeImage01 from '../images/MOSHED-2020-10-13-21-44-47.gif'
import shereeImage02 from '../images/ezgif.com-optimize-2.gif'
import shereeImage03 from '../images/ezgif.com-optimize-3.gif'
import shereeImage04 from '../images/ezgif.com-optimize.gif'
import shereeImage05 from '../images/MOSHED-2020-10-13-21-45-5.gif'

import { Layout, Article, Wrapper, Button, SectionTitle } from '../components'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  padding: 3rem 6rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;
`

const Hero = styled.div`
  grid-column: 2;
  padding: 3rem 2rem 6rem 2rem;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.grey.dark};

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1rem 4rem 1rem;
  }
  header,
  section {
    padding: 30px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
  section.about {
    background-color: #000;
  }

  section.about p {
    max-width: 30em;
    margin-top: 5em;
  }

  section.photo {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  section.photo1 {
    background-color: #000;
  }

  section.photo2 {
    background-color: #000;
  }

  section.photo3 {
    background-color: #000;
  }

  section.photo4 {
    background-color: #000;
  }

  section.photo5 {
    background-color: #000;
  }

  section.photo img {
    max-width: 1000px;
  }

  p {
    font-size: 1.68rem;
    margin-top: -1rem;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 1.45rem;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1.25rem;
    }
  }
`

const IndexPage = ({
  data: {
    allMdx: { edges: postEdges },
  },
}) => (
  <Layout>
    <Wrapper>
      <Hero>
        <header>
          <h1>Sheree is a generalist.</h1>
        </header>
        <section className="about">
          <p>
            They do testing and automation for work. They do web development for fun. They also do technical program
            management because it needs to be done. They love systems thinking and operations processes. They cut their
            teeth in the DIY world and got into tech through their fascination with the do-it-yourself nature of open
            source. They believe in co-mentorship, skill-sharing, and the criticality of mutual aid. Residing in
            Portland, OR, and working remote every day. You can reach out to them at shereemaria@gmail.com
          </p>
        </section>
        <section className="photo photo1">
          <img src={shereeImage01} alt="" />
        </section>

        <section className="about">
          <p>
            They have a{' '}
            <Link to="/now">
              <Button small>Now</Button>
            </Link>{' '}
            page to show you what they've been up to lately.
          </p>
        </section>

        <section className="photo photo2">
          <img src={shereeImage02} alt="" />
        </section>

        <section className="about">
          <p>
            They maintain a{' '}
            <Link to="/reading">
              <Button small>Reading</Button>
            </Link>{' '}
            page of current and recent readings and books that might interest you as they have interested them.
          </p>
        </section>

        <section className="photo photo3">
          <img src={shereeImage03} alt="" />
        </section>

        <section className="about">
          <p>
            They have a{' '}
            <Link to="/resume">
              <Button small>Resume</Button>
            </Link>{' '}
            for your professional convenience. You can hire them through <a href="https://nicecatch.co">NiceCatch.co</a>
          </p>
        </section>

        <section className="photo photo4">
          <img src={shereeImage04} alt="" />
        </section>

        <section className="about">
          <p>
            They maintain a{' '}
            <Link to="/uses">
              <Button small>Uses</Button>
            </Link>{' '}
            page because they use a lot of things and would like to share them with you.
          </p>
        </section>

        <section className="photo photo5">
          <img src={shereeImage05} alt="" />
        </section>

        <section className="about">
          <p>
            This is their journal where they log things they were doing and thinking during their work, on the clock or
            off.
          </p>
        </section>
      </Hero>
      <Content>
        <SectionTitle>Work Journal Entries</SectionTitle>
        {postEdges.map(post => (
          <Article
            title={post.node.frontmatter.title}
            date={post.node.frontmatter.date}
            excerpt={post.node.excerpt}
            timeToRead={post.node.timeToRead}
            slug={post.node.fields.slug}
            categories={post.node.frontmatter.categories}
            key={post.node.fields.slug}
          />
        ))}
      </Content>
    </Wrapper>
  </Layout>
)

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const IndexQuery = graphql`
  query IndexQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
            categories
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
